const user = localStorage.getItem('user') || []
const token = localStorage.getItem('authToken') || ''

const Storage = {

    saveUser: (user) => {
        localStorage.setItem('user', JSON.stringify(user));
    },

    getUser: () => {
        return JSON.parse(localStorage.getItem('user')) || {};
    },

    updateUserCredentialCnt: (cnt) => {
        let user = JSON.parse(localStorage.getItem('user')) || {}
        user.credentialCnt = cnt
        localStorage.setItem('user', JSON.stringify(user));
    },

    saveToken: (token) => {
        localStorage.setItem('token', token)
    },

    getToken: () => {
        return localStorage.getItem('authToken') || ""
    },

    deleteToken: () => {
        localStorage.removeItem('token')
    },

    deleteUser: () => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }

}

export { user, token, Storage }