import React from "react";
import { Form, Button, Modal, InputGroup, Col, Row } from "react-bootstrap";
import { AdvanceTablesWidget2, AdvanceTablesWidget4 } from "../widgets";

import { getLoginMethod } from "../../../app/utils/common";
import Loader from "../../../app/pages/components/Loader";

export function User(props) {
  const {
    handleShow,
    handleClose,
    userData,
    isLoading,
    isShow,
    modalLoading,
    userDetails,
  } = props;

  return (
    <>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div
            className="table-responsive"
            style={{ maxHeight: "900px", overflowY: "scroll" }}
          >
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th style={{ minWidth: "100px" }}>Email Address</th>
                  <th style={{ minWidth: "130px" }}>Login Method</th>
                  <th style={{ minWidth: "80px" }}>View Details</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <Loader />
                ) : (
                  userData &&
                  userData.map((user, index) => {
                    //console.log("user.email ", index, user.email);
                    return (
                      <tr key={index}>
                        <td>{user.email}</td>
                        <td>
                          {getLoginMethod(user.providerData[0].providerId)}
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={(e) => {
                              handleShow(e, user.uid, user.email);
                            }}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Modal show={isShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              User Details : {userDetails.email ? userDetails.email : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalLoading ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      <th style={{ minWidth: "100px" }}>Subscription Tier</th>
                      <th style={{ minWidth: "130px" }}>Subscription From</th>
                      <th style={{ minWidth: "80px" }}>Credential Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{userDetails.userSubscriptionTier}</td>
                      <td>
                        {userDetails.userSubscribeFrom
                          ? userDetails.userSubscribeFrom
                          : "NA"}
                      </td>
                      <td>{userDetails.credentialCount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
