import moment from "moment";

export const getLoginMethod = (providerMethod = "password") => {
  if (providerMethod == "google.com") {
    return "Google";
  } else if (providerMethod == "facebook.com") {
    return "Facebook";
  } else if (providerMethod == "apple.com") {
    return "Apple";
  } else {
    return "Email Address";
  }
};

export const getFormatedDate = (date) => {
  if (date) {
    return moment(date, moment.ISO_8601).format("MM-DD-YYYY");
  }
  return "";
};

export const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
