/*import React from "react";
import { Dashboard } from "../../_metronic/_partials";

export function DashboardPage() {
  return <Dashboard />;
}
*/

import React, { Component } from "react";
import { Dashboard } from "../../_metronic/_partials";
import { SupportTicketService } from "../services/SupportTicket";

export class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      ticketCount: "",
    };
  }

  getSupportTicketList = async () => {
    this.setState({ isLoading: true });

    try {
      //get user details
      const supportTicketDetails = await SupportTicketService.getOpenSupportTicketCount();
      //console.log("supportTicketDetails", supportTicketDetails);

      this.setState({
        isLoading: false,
        ticketCount: supportTicketDetails.count,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      // console.log("error ", error)
    }
  };

  componentDidMount() {
    this.getSupportTicketList();
  }

  render() {
    const { ticketCount, isLoading } = this.state;

    return (
      <div>
        <Dashboard ticketCount={ticketCount} isLoading={isLoading} />
      </div>
    );
  }
}
