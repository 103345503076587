function isEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

function isPasswordValid(password) {
  if (password.length >= 8) {
    return true;
  }
  return false;
}

export const validateWebsiteContentForm = (formData) => {
  const {
    about,
    payment_disclaimer,
    upgrade_page,
    privacy_policy,
    terms_condition,
  } = formData;

  let errs = {};
  let status = true;

  if (!about) {
    status = false;
    errs["about"] = {
      status: false,
      err_msg: "Please enter about",
    };
  }

  if (!payment_disclaimer) {
    status = false;
    errs["payment_disclaimer"] = {
      status: false,
      err_msg: "Please enter payment disclaimer",
    };
  }

  if (!upgrade_page) {
    status = false;
    errs["upgrade_page"] = {
      status: false,
      err_msg: "Please enter upgrade page",
    };
  }

  if (!privacy_policy) {
    status = false;
    errs["privacy_policy"] = {
      status: false,
      err_msg: "Please enter privacy policy",
    };
  }

  if (!terms_condition) {
    status = false;
    errs["terms_condition"] = {
      status: false,
      err_msg: "Please enter terms condition",
    };
  }

  return { status, errs };
};

export const validateCreateUserForm = (formData) => {
  const { firstName, lastName, email, password, planId } = formData;

  let errs = {};
  let status = true;

  if (!firstName) {
    status = false;
    errs["firstName"] = {
      status: false,
      err_msg: "Please enter First Name",
    };
  }

  if (!lastName) {
    status = false;
    errs["lastName"] = {
      status: false,
      err_msg: "Please enter Last Name",
    };
  }

  if (!email) {
    status = false;
    errs["email"] = {
      status: false,
      err_msg: "Please enter email",
    };
  } else {
    if (!isEmail(email)) {
      status = false;
      errs["email"] = {
        status: false,
        err_msg: "Please enter valid email",
      };
    }
  }

  if (!password) {
    status = false;
    errs["password"] = {
      status: false,
      err_msg: "Please enter password",
    };
  }

  // if (!planId) {
  //   status = false;
  //   errs["planId"] = {
  //     status: false,
  //     err_msg: "Please enter terms plan Id",
  //   };
  // }

  return { status, errs };
};
