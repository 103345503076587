import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Loader from "../../../app/pages/components/Loader";
import { getFormatedDate, Capitalize } from "../../../app/utils/common";

export function SupportTicket(props) {
  const {
    supportTicketData,
    isLoading,
    handleShow,
    handleClose,
    isShow,
    selectedTicket,
    updateStatus,
    onChange,
    ddl_status,
    isSubmit,
    filter_status,
    onFilterStatus,
  } = props;

  return (
    <>
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* Head */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Support Ticket Listing
            </span>
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>
                  <b>Status :</b>
                </Form.Label>
                <Form.Control
                  className="col-md-3"
                  as="select"
                  name="filter_status"
                  value={filter_status}
                  onChange={onFilterStatus}
                >
                  <option value="all">All</option>
                  <option value="pending">Pending</option>
                  <option value="resolved">Resolved</option>
                </Form.Control>
              </Form.Group>
            </Form>

            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th style={{ minWidth: "100px" }}>Name</th>
                    <th style={{ minWidth: "130px" }}>Email Address</th>
                    <th style={{ minWidth: "130px" }}>Date</th>
                    <th style={{ minWidth: "130px" }}>Status</th>
                    <th style={{ minWidth: "100px" }}>View</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    supportTicketData &&
                    supportTicketData.map((ticket, index) => {
                      let labelCss =
                        ticket.status && ticket.status == "pending"
                          ? "label label-lg label-light-danger label-inline"
                          : ticket.status == "inprocess"
                          ? "label label-lg label-light-warning label-inline"
                          : "label label-lg label-light-success label-inline";
                      return (
                        <tr key={index}>
                          <td>{ticket.name}</td>
                          <td>{ticket.email}</td>
                          <td>{getFormatedDate(ticket.date)}</td>
                          <td>
                            <span className={labelCss}>
                              {Capitalize(ticket.status)}
                            </span>
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              type="submit"
                              onClick={(e) => {
                                handleShow(e, ticket);
                              }}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal show={isShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ticket Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicterms_condition">
                <Form.Label>
                  <b>Name :</b>
                </Form.Label>
                <p>{selectedTicket.name}</p>
              </Form.Group>

              <Form.Group controlId="formBasicterms_condition">
                <Form.Label>
                  <b>Email :</b>
                </Form.Label>
                <p>{selectedTicket.email}</p>
              </Form.Group>

              <Form.Group controlId="formBasicterms_condition">
                <Form.Label>
                  <b>Date :</b>
                </Form.Label>
                <p>{getFormatedDate(selectedTicket.date)}</p>
              </Form.Group>

              <Form.Group controlId="formBasicterms_condition">
                <Form.Label>
                  <b>Message :</b>
                </Form.Label>
                <p>{selectedTicket.message}</p>
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>
                  <b>Status :</b>
                </Form.Label>
                <Form.Control
                  className="col-md-6"
                  as="select"
                  name="ddl_status"
                  value={ddl_status}
                  onChange={onChange}
                >
                  <option value="">Select Status</option>
                  <option value="pending">Pending</option>
                  <option value="resolved">Resolved</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="primary" onClick={updateStatus}>
              Update
            </Button> */}

            <Button
              variant="primary"
              type="submit"
              disabled={isSubmit}
              onClick={updateStatus}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              Update
              {isSubmit ? (
                <span className="ml-3 spinner spinner-white"></span>
              ) : (
                <div />
              )}
            </Button>

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
