import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loader = () => {
    return (
        <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '000' }}>
            <div>
                <Spinner animation="border" />
            </div>
        </div>
    )
}

export default Loader
