import React, { Component } from "react";
import { Button, Form, InputGroup, Col, Row } from "react-bootstrap";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { WebsiteContentService } from "../services/WebsiteContent";
import { validateWebsiteContentForm } from "../utils/Validations";
import { Toast } from "../utils/Toast";
import OverlayLoader from "./components/OverlayLoader";
import Loader from "./components/Loader";

const wrapperStyle = {
  // border: "1px solid #f9efef",
  border: "3px solid #f9efef",
};

const editorStyle = {
  maxHeight: "600px",
  padding: "20px",
  border: "2px solid #f9efef",
};

export class ContentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSubmit: false,

      editorStateAboutUs: "",
      editorStatePaymentDisclaimer: "",
      editorStateUpgradePage: "",
      editorStatePrivacyPolicy: "",
      editorStateTermsCondition: "",
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({ isSubmit: true });

    const {
      editorStateAboutUs,
      editorStatePaymentDisclaimer,
      editorStateUpgradePage,
      editorStatePrivacyPolicy,
      editorStateTermsCondition,
    } = this.state;

    let markupAbout,
      markupPayment,
      markupUpgarde,
      markupPrivacy,
      markupTerms = "";

    if (editorStateAboutUs != "") {
      markupAbout = draftToHtml(
        convertToRaw(editorStateAboutUs.getCurrentContent())
      );
    }

    if (editorStatePaymentDisclaimer != "") {
      markupPayment = draftToHtml(
        convertToRaw(editorStatePaymentDisclaimer.getCurrentContent())
      );
    }

    if (editorStateUpgradePage != "") {
      markupUpgarde = draftToHtml(
        convertToRaw(editorStateUpgradePage.getCurrentContent())
      );
    }

    if (editorStatePrivacyPolicy != "") {
      markupPrivacy = draftToHtml(
        convertToRaw(editorStatePrivacyPolicy.getCurrentContent())
      );
    }

    if (editorStateTermsCondition != "") {
      markupTerms = draftToHtml(
        convertToRaw(editorStateTermsCondition.getCurrentContent())
      );
    }

    const FormData = {
      about: markupAbout,
      payment_disclaimer: markupPayment,
      upgrade_page: markupUpgarde,
      privacy_policy: markupPrivacy,
      terms_condition: markupTerms,
    };

    await WebsiteContentService.updateWebsiteContent(FormData)
      .then((response) => {
        console.log("response ", response);

        if (response.status === true) {
          Toast.showSuccessToast(
            "Website content has been updated sussessfully."
          );
        }

        this.setState({ isSubmit: false });
      })
      .catch((error) => {
        this.setState({ isSubmit: false });

        console.log("catch error ", error);
      });
  };

  onEditorStateChangeAbout = (editorStateAboutUs) =>
    this.setState({ editorStateAboutUs });

  onEditorStateChangePaymentDisclaimer = (editorStatePaymentDisclaimer) =>
    this.setState({ editorStatePaymentDisclaimer });

  onEditorStateChangeUpgradePage = (editorStateUpgradePage) =>
    this.setState({ editorStateUpgradePage });

  onEditorStateChangePrivacy = (editorStatePrivacyPolicy) =>
    this.setState({ editorStatePrivacyPolicy });

  onEditorStateChangeTerms = (editorStateTermsCondition) =>
    this.setState({ editorStateTermsCondition });

  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      let getStateAbout,
        getStatePayment,
        getStateUpgarde,
        getStatePrivacy,
        getStateTerms = "";

      const websiteContent = await WebsiteContentService.getWebsiteContent();
      //console.log('websiteContent',websiteContent.data[0])
      //about us content retrive
      const contentBlockAbout = htmlToDraft(websiteContent.data[0].about);
      if (contentBlockAbout) {
        const contentStateAbout = ContentState.createFromBlockArray(
          contentBlockAbout.contentBlocks
        );
        getStateAbout = EditorState.createWithContent(contentStateAbout);
      }
      //Payment content retrive
      const contentBlockPayment = htmlToDraft(
        websiteContent.data[0].payment_disclaimer
      );
      if (contentBlockPayment) {
        const contentStatePayment = ContentState.createFromBlockArray(
          contentBlockPayment.contentBlocks
        );
        getStatePayment = EditorState.createWithContent(contentStatePayment);
      }
      //upgrade content retrive
      const contentBlockUpgrade = htmlToDraft(
        websiteContent.data[0].upgrade_page
      );
      if (contentBlockUpgrade) {
        const contentStateUpgrade = ContentState.createFromBlockArray(
          contentBlockUpgrade.contentBlocks
        );
        getStateUpgarde = EditorState.createWithContent(contentStateUpgrade);
      }
      //privacy content retrive
      const contentBlockPrivacy = htmlToDraft(
        websiteContent.data[0].privacy_policy
      );
      if (contentBlockPrivacy) {
        const contentStatePrivacy = ContentState.createFromBlockArray(
          contentBlockPrivacy.contentBlocks
        );
        getStatePrivacy = EditorState.createWithContent(contentStatePrivacy);
      }
      //terms content retrive
      const contentBlockTerms = htmlToDraft(
        websiteContent.data[0].terms_condition
      );
      if (contentBlockTerms) {
        const contentStateTerms = ContentState.createFromBlockArray(
          contentBlockTerms.contentBlocks
        );
        getStateTerms = EditorState.createWithContent(contentStateTerms);
      }

      this.setState({
        isLoading: false,
        editorStateAboutUs: getStateAbout,
        editorStatePaymentDisclaimer: getStatePayment,
        editorStateUpgradePage: getStateUpgarde,
        editorStatePrivacyPolicy: getStatePrivacy,
        editorStateTermsCondition: getStateTerms,
      });
    } catch (error) {
      this.setState({ isLoading: false });

      console.log("error ", error);
    }
  }

  render() {
    const {
      isLoading,
      isSubmit,

      editorStateAboutUs,
      editorStatePaymentDisclaimer,
      editorStateUpgradePage,
      editorStatePrivacyPolicy,
      editorStateTermsCondition,
    } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            {isLoading ? (
              <Loader />
            ) : (
              <Form method="POST">
                <div className="card card-custom gutter-b example example-compact">
                  <div className="card-body">
                    <Form.Group controlId="formBasicAbout">
                      <Form.Label>
                        <h3>About Us</h3>
                      </Form.Label>

                      <Editor
                        editorState={editorStateAboutUs}
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={wrapperStyle}
                        editorStyle={editorStyle}
                        onEditorStateChange={this.onEditorStateChangeAbout}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="card card-custom gutter-b example example-compact">
                  <div className="card-body">
                    <Form.Group controlId="formBasicPayment">
                      <Form.Label>
                        <h3>Payment Disclaimer</h3>
                      </Form.Label>

                      <Editor
                        editorState={editorStatePaymentDisclaimer}
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={wrapperStyle}
                        editorStyle={editorStyle}
                        onEditorStateChange={
                          this.onEditorStateChangePaymentDisclaimer
                        }
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="card card-custom gutter-b example example-compact">
                  <div className="card-body">
                    <Form.Group controlId="formBasicUpgradePage">
                      <Form.Label>
                        <h3>Upgrade Page</h3>
                      </Form.Label>

                      <Editor
                        editorState={editorStateUpgradePage}
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={wrapperStyle}
                        editorStyle={editorStyle}
                        onEditorStateChange={
                          this.onEditorStateChangeUpgradePage
                        }
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="card card-custom gutter-b example example-compact">
                  <div className="card-body">
                    <Form.Group controlId="formBasicPrivacy">
                      <Form.Label>
                        <h3>Privacy Policy</h3>
                      </Form.Label>

                      <Editor
                        editorState={editorStatePrivacyPolicy}
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={wrapperStyle}
                        editorStyle={editorStyle}
                        onEditorStateChange={this.onEditorStateChangePrivacy}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="card card-custom gutter-b example example-compact">
                  <div className="card-body">
                    <Form.Group controlId="formBasicTerms">
                      <Form.Label>
                        <h3>Terms And Condition</h3>
                      </Form.Label>

                      <Editor
                        editorState={editorStateTermsCondition}
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={wrapperStyle}
                        editorStyle={editorStyle}
                        onEditorStateChange={this.onEditorStateChangeTerms}
                      />
                    </Form.Group>
                  </div>
                </div>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmit}
                  onClick={this.onSubmit}
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                >
                  Save Content
                  {isSubmit ? (
                    <span className="ml-3 spinner spinner-white"></span>
                  ) : (
                    <div />
                  )}
                </Button>
              </Form>
            )}
          </div>
        </div>
      </div>
    );
  }
}
