import axios, { BASE_URL } from "../utils/axios";
import { routes } from "./urls";

export const UserService = {
  getAllUsers: () => {
    return new Promise((resolve) => {
      axios.get(routes.getAllUsers).then((response) => {
        resolve(response);
      });
    });
  },

  getUserDetails: (body) => {
    return new Promise((resolve) => {
      axios.post(routes.getUserDetails, body).then((response) => {
        resolve(response);
      });
    });
  },

  createNewUser: (body) => {
    return new Promise((resolve) => {
      axios.post(routes.createNewUser, body).then((response) => {
        resolve(response);
      });
    });
  },

  createAndSendInvoice: (body) => {
    return new Promise((resolve) => {
      axios.post(routes.createAndSendInvoice, body).then((response) => {
        resolve(response);
      });
    });
  },
};
