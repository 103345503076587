export const routes = {
  getOpenSupportTickets: "admin/getOpenSupportTickets",

  getAllUsers: "admin/getAllUsers",
  getUserDetails: "admin/getUserDetails",
  createNewUser: "auth/sign_up",
  createAndSendInvoice: "send-invoice-customer",

  getSupportTickets: "admin/getSupportTicketData",
  updateTicketStatus: "admin/updateSupportTicketStatus",

  getWebsiteContent: "admin/getWebsiteContent",
  updateWebsiteContent: "admin/updateWebsiteContent",
};
