import { toast } from "react-toastify";

export const Toast = {
  showSuccessToast: (message) => {
    toast(message, {
      type: "success",
    });
  },

  showErrorToast: (message) => {
    toast(message, {
      type: "error",
    });
  },
};
