import axios, { BASE_URL } from "../utils/axios";
import { routes } from "./urls";

export const SupportTicketService = {
  getSupportTicketData: () => {
    return new Promise((resolve) => {
      axios.get(routes.getSupportTickets).then((response) => {
        resolve(response);
      });
    });
  },

  updateTicketStatus: (body) => {
    return new Promise((resolve) => {
      axios.post(routes.updateTicketStatus, body).then((response) => {
        resolve(response);
      });
    });
  },

  getOpenSupportTicketCount: () => {
    return new Promise((resolve) => {
      axios.get(routes.getOpenSupportTickets).then((response) => {
        resolve(response);
      });
    });
  },
};
