import React, { Component } from "react";
import { SupportTicket } from "../../_metronic/_partials";
import { Toast } from "../utils/Toast";

import { SupportTicketService } from "../services/SupportTicket";

export class SupportTicketPage extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      isLoading: false,
      modalLoading: false,
      isShow: false,
      isSubmit: false,
      supportTicketData: [],
      supportTicketDataCopy: [],
      selectedTicket: [],
      ddl_status: "",
      filter_status: "",
    };
  }

  onChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  handleClose() {
    this.setState({ isShow: false, selectedTicket: [] });
  }

  async handleShow(e, ticket) {
    this.setState({
      isShow: true,
      selectedTicket: ticket,
      ddl_status: ticket.status,
    });
  }

  updateStatus = async () => {
    try {
      this.setState({ isSubmit: true });

      const { selectedTicket, ddl_status } = this.state;

      //console.log("ddl_status ", ddl_status);
      //console.log("selectedTicket ", selectedTicket);

      const FormData = {
        status: ddl_status,
        ticketId: selectedTicket.docId,
      };

      await SupportTicketService.updateTicketStatus(FormData)
        .then((response) => {
          if (response.status === true) {
            this.handleClose();
            Toast.showSuccessToast(
              "Ticket status has been updated sussessfully."
            );
          }

          this.getSupportTicketList();
          //this.setState({ isSubmit: false });
        })
        .catch((error) => {
          this.setState({ isSubmit: false });

          console.log("catch error ", error);
        });
    } catch (error) {
      this.setState({ isSubmit: false });

      // console.log("error ", error)
    }
  };

  onFilterStatus = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState({ [name]: value });

    const { supportTicketDataCopy } = this.state;

    const filter_status = value;

    //console.log("filter_status ", filter_status);

    if (filter_status != "" && filter_status == "all") {
      this.setState({ supportTicketData: supportTicketDataCopy });
    } else if (filter_status != "") {
      const filteredData =
        supportTicketDataCopy &&
        supportTicketDataCopy.filter((ticket) => {
          if (
            ticket.status &&
            ticket.status.toLowerCase().includes(filter_status.toLowerCase())
          ) {
            return ticket;
          }
        });

      this.setState({ supportTicketData: filteredData });
    } else {
      //console.log("Elseeeeeee");
      this.setState({ supportTicketData: supportTicketDataCopy });
    }
  };

  getSupportTicketList = async () => {
    this.setState({ isLoading: true });

    try {
      const params = new URLSearchParams(this.props.location.search);
      const type = params.get("type");

      //console.log("params ", params);
      //console.log("type ", type);

      //get user details
      const supportTicketDetails = await SupportTicketService.getSupportTicketData();
      //console.log("supportTicketDetails", supportTicketDetails);

      const ticketList = supportTicketDetails.data;

      if (type) {
        const filteredData =
          ticketList &&
          ticketList.filter((ticket) => {
            if (
              ticket.status &&
              ticket.status.toLowerCase().includes(type.toLowerCase())
            ) {
              return ticket;
            }
          });

        this.setState({
          isLoading: false,
          filter_status: type,
          supportTicketData: filteredData,
          supportTicketDataCopy: ticketList,
        });
      } else {
        this.setState({
          isLoading: false,
          supportTicketData: ticketList,
          supportTicketDataCopy: ticketList,
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });

      // console.log("error ", error)
    }
  };

  componentDidMount() {
    this.getSupportTicketList();
  }

  render() {
    const {
      supportTicketData,
      isLoading,
      selectedTicket,
      isShow,
      ddl_status,
      isSubmit,
      filter_status,
    } = this.state;

    return (
      <div>
        <SupportTicket
          supportTicketData={supportTicketData}
          isLoading={isLoading}
          isShow={isShow}
          handleShow={this.handleShow}
          handleClose={this.handleClose}
          selectedTicket={selectedTicket}
          updateStatus={this.updateStatus}
          onChange={this.onChange}
          ddl_status={ddl_status}
          isSubmit={isSubmit}
          filter_status={filter_status}
          onFilterStatus={this.onFilterStatus}
        />
      </div>
    );
  }
}
