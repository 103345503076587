import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Loader from "../../../app/pages/components/Loader";
import { Redirect, Link } from "react-router-dom";

export function Dashboard(props) {
  const { ticketCount, isLoading } = props;

  //console.log("ticketCount ", ticketCount);
  return (
    <>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <Link to="/support?type=pending">
            <div className="col-lg-6 col-xxl-4">
              <div className={`card card-custom`}>
                <div className="card-body d-flex flex-column p-0">
                  <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                    <div className="d-flex flex-column mr-2">
                      <a
                        href="javascript:void(0)"
                        className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
                      >
                        Open Tickets
                      </a>
                      {/* <span className="text-muted font-weight-bold mt-2">
                         Your Weekly Sales Chart
                       </span> */}
                    </div>

                    <span className="symbol symbol-light-success symbol-45">
                      <span className="symbol-label font-weight-bolder font-size-h6">
                        {ticketCount ? ticketCount : 0}
                      </span>
                    </span>
                  </div>
                  {/* <div
                 id="kt_stats_widget_7_chart"
                 className="card-rounded-bottom"
                 style={{ height: "150px" }}
               ></div> */}
                </div>
              </div>

              {/* <StatsWidget11 className="card-stretch card-stretch-half gutter-b" />
               <StatsWidget12 className="card-stretch card-stretch-half gutter-b" /> */}
            </div>
          </Link>
        )}
      </div>
    </>
  );
}

/*import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../layout";
import {Demo1Dashboard} from "./Demo1Dashboard";
import {Demo2Dashboard} from "./Demo2Dashboard";
import {Demo3Dashboard} from "./Demo3Dashboard";
import {Demo4Dashboard} from "./Demo4Dashboard";
import {Demo5Dashboard} from "./Demo5Dashboard";
import {Demo6Dashboard} from "./Demo6Dashboard";
import {Demo7Dashboard} from "./Demo7Dashboard";

export function Dashboard() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            demo: objectPath.get(
                uiService.config,
                "demo"
            )};
    }, [uiService]);
    return <>
        {layoutProps.demo === 'demo1' && <Demo1Dashboard />}
        {layoutProps.demo === 'demo2' && <Demo2Dashboard />}
        {layoutProps.demo === 'demo3' && <Demo3Dashboard />}
        {layoutProps.demo === 'demo4' && <Demo4Dashboard />}
        {layoutProps.demo === 'demo5' && <Demo5Dashboard />}
        {layoutProps.demo === 'demo6' && <Demo6Dashboard />}
        {layoutProps.demo === 'demo7' && <Demo7Dashboard />}
    </>;
}
*/
