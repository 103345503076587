import axios from 'axios'

// import { server } from './URL';
import { token, Storage } from './Storage';
// import store from './../store'
// import { logout } from '../actions/AuthActions';
// import * as firebase from 'firebase/app'
// import 'firebase/auth'
import { SERVER_URL } from '../url'

export const BASE_URL = SERVER_URL
//const token = '123456'

//export const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? server.dev : server.prod

//const { dispatch } = store

const instance = axios.create({
    // url: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? server.dev : server.prod,
    baseURL: BASE_URL,
    // timeout: 1000,
    headers: {
        "authorization": token
    },

});

instance.interceptors.request.use(function (config) {
    

    const token = Storage.getToken();

    // console.log('token',token)

    // if (token) {
        config.headers['authorization'] = 'Bearer ' + token;
    // }   
    

    return config;
}, function (error) {

    // Do something with request error
    return Promise.reject(error)
})

// Add a response interceptor
instance.interceptors.response.use(function (response) {

    //let responseData = response

    let responseData = response.data

    /*if (response.data.isAuthenticated == false) {
        Storage.deleteUser()
        dispatch(logout())
    }*/

    //responseData = response.data

    // Do something before request is sent
    return responseData;
}, function (error) {

    // Do something with request error
    return Promise.reject(error);
});

export default instance;