import React, { Component } from "react";
import { Form, Button, Modal, InputGroup, Col, Row } from "react-bootstrap";
import { User } from "../../_metronic/_partials";

import { UserService } from "../services/User";
import Loader from "./components/Loader";
import { validateCreateUserForm } from "../utils/Validations";
import { Toast } from "../utils/Toast";

export class UserPage extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    // this.handleAddModalClose = this.handleAddModalClose.bind(this);

    this.state = {
      isLoading: false,
      modalLoading: false,
      isShow: false,
      userData: [],
      userDataCopy: [],
      userDetails: {
        user: "",
        credentialCount: "",
        userSubscriptionTier: "",
        userSubscribeFrom: "",
      },
      searchText: "",

      isAddModalShow: false,
      addModalLoading: false,
      isSubmit: false,

      firstName: "",
      lastName: "",
      email: "",
      password: "",
      planId: "",
      errs: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        planId: "",
      },
    };
  }

  onChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  handleClose() {
    this.setState({ isShow: false, userDetails: "" });
  }

  async handleShow(e, uid, email) {
    this.setState({ isShow: true, modalLoading: true });

    console.log("api call", uid);

    const data = {
      uid,
    };
    //call api to get user details
    const details = await UserService.getUserDetails(data);

    console.log("details", details);

    //let userEntitlement = userSubscription.subscriber.entitlements

    const userDetails = {
      email: email,
      credentialCount: details.credentialCount,
      userSubscriptionTier: details.userSubscriptionTier,
      userSubscribeFrom: details.userSubscribeFrom,
    };

    this.setState({
      userDetails,
    });

    this.setState({ modalLoading: false });
  }

  searchByEmail = (e) => {
    e.preventDefault();

    const { userData, userDataCopy, searchText } = this.state;

    const userData1 = userDataCopy;

    if (searchText != "") {
      const filteredData =
        userData1 &&
        userData1.filter((user) => {
          if (
            user.email &&
            user.email.toLowerCase().includes(searchText.toLowerCase())
            //user.email.toLowerCase() === searchText.toLowerCase()
          ) {
            return user;
          }
        });

      this.setState({ userData: filteredData });
    } else {
      //console.log("Elseeeeeee");
      //this.setState({ userData: userDataCopy });
    }
  };

  getUserList = async () => {
    this.setState({ isLoading: true });

    try {
      //get user details
      const userDetails = await UserService.getAllUsers();

      //console.log("userDetails", userDetails);

      this.setState({
        isLoading: false,
        userData: userDetails.data,
        userDataCopy: userDetails.data,
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  onRefreshList = (e) => {
    e.preventDefault();
    this.getUserList();
  };
  componentDidMount() {
    this.getUserList();
  }

  handleAddModalClose = (e) => {
    e.preventDefault();
    this.setState({
      isAddModalShow: false,
      isSubmit: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      planId: "",
      errs: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        planId: "",
      },
    });
  };

  onAddUserClick = (e) => {
    e.preventDefault();
    this.setState({ isAddModalShow: true });
  };

  handleCreateUser = async (e) => {
    this.setState({ isSubmit: true, addModalLoading: true });

    const { status, errs } = validateCreateUserForm(this.state);

    this.setState({ errs });

    const { firstName, lastName, email, password, planId } = this.state;

    if (!status) {
      this.setState({ errs, isSubmit: false, addModalLoading: false });
      return;
    } else {
      const data = {
        firstName,
        lastName,
        email,
        password,
      };
      await UserService.createNewUser(data)
        .then(async (response) => {
          console.log("response ", response);
          //response  {status: true, uid: "bWb0lu2ipEazCJEmUyQY6fkDoNj1"}
          //if (response.status === 200) {
          Toast.showSuccessToast("User has been created sussessfully.");
          //}
        })
        .catch((error) => {
          console.log("catch error ", error);
          this.setState({ errs, isSubmit: false, addModalLoading: false });
        });
      //create and send invoice functinality
      if (planId) {
        const invoiceData = {
          // customer_id,
          planId,
          email,
          paymentMethod: "",
        };
        console.log("invoiceData ", invoiceData);
        await UserService.createAndSendInvoice(invoiceData)
          .then((response) => {
            console.log("response ", response);
            Toast.showSuccessToast(
              "Invoice has been sent to user sussessfully."
            );
            this.setState({
              isSubmit: false,
              isAddModalShow: false,
              addModalLoading: false,

              firstName: "",
              lastName: "",
              email: "",
              password: "",
              planId: "",
              errs: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                planId: "",
              },
            });
            this.getUserList();
          })
          .catch((error) => {
            console.log("error ", error);
            this.setState({ errs, isSubmit: false, addModalLoading: false });
          });
      }
    }
  };

  onChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  render() {
    const {
      userData,
      isLoading,
      isShow,
      modalLoading,
      userDetails,
      searchText,
      isAddModalShow,
      addModalLoading,
      isSubmit,
      firstName,
      lastName,
      email,
      password,
      planId,
      errs,
    } = this.state;

    return (
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* Head */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              User Listing
            </span>
          </h3>
        </div>

        <Form method="GET">
          <Form.Row className="col-md-12">
            <Form.Group as={Col} controlId="formGridSearch">
              <Form.Control
                className="col-md-6"
                type="text"
                name="searchText"
                value={searchText}
                placeholder="Search By Email"
                onChange={this.onChange}
                onKeyUp={this.searchByEmail}
              />
            </Form.Group>

            <Form.Group controlId="formGridSearch">
              <Button
                className="float-right m-1"
                variant="info"
                type="submit"
                onClick={this.onRefreshList}
              >
                Refresh
              </Button>
            </Form.Group>

            <Form.Group controlId="formGridSearch">
              <Button
                className="float-right m-1"
                variant="success"
                type="submit"
                onClick={this.onAddUserClick}
              >
                Create User
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>

        <Modal show={isAddModalShow} onHide={this.handleAddModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create New User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form>
                <Form.Group as={Row} controlId="formHorizontalFirstName">
                  <Form.Label column sm={3}>
                    First Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={firstName}
                      onChange={this.onChange}
                    />
                    <Form.Text className="form-text text-danger text-left">
                      {errs.firstName ? errs.firstName.err_msg : ""}
                    </Form.Text>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalLastName">
                  <Form.Label column sm={3}>
                    Last Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={this.onChange}
                    />
                    <Form.Text className="form-text text-danger text-left">
                      {errs.lastName ? errs.lastName.err_msg : ""}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Form.Label column sm={3}>
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                    />
                    <Form.Text className="form-text text-danger text-left">
                      {errs.email ? errs.email.err_msg : ""}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalPassword">
                  <Form.Label column sm={3}>
                    Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={this.onChange}
                    />
                    <Form.Text className="form-text text-danger text-left">
                      {errs.password ? errs.password.err_msg : ""}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalPrice">
                  <Form.Label column sm={3}>
                    Price Id
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Price Id"
                      name="planId"
                      value={planId}
                      onChange={this.onChange}
                    />
                    <Form.Text className="form-text text-danger text-left">
                      {errs.planId ? errs.planId.err_msg : ""}
                    </Form.Text>
                  </Col>
                </Form.Group>
              </Form>
              <p className="text-primary">
                <b>Note:</b> Price Id is stripe custom product price Id.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* {addModalLoading ? <Loader /> : <div></div>} */}
            <Button
              variant="primary"
              disabled={isSubmit}
              onClick={this.handleCreateUser}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              Create
              {isSubmit ? (
                <span className="ml-3 spinner spinner-white"></span>
              ) : (
                <div />
              )}
            </Button>
            <Button
              variant="secondary"
              onClick={this.handleAddModalClose}
              className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <User
          isLoading={isLoading}
          modalLoading={modalLoading}
          isShow={isShow}
          handleShow={this.handleShow}
          handleClose={this.handleClose}
          userData={userData}
          userDetails={userDetails}
        />
      </div>
    );
  }
}
