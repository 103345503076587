
import axios, { BASE_URL } from '../utils/axios'
import { routes } from './urls'

export const WebsiteContentService = {
    
    getWebsiteContent: () => {
        return new Promise((resolve) => {
            axios.get(routes.getWebsiteContent).then((response) => {
                resolve(response)
            })
        })
    },

    updateWebsiteContent: (body) => {
        return new Promise((resolve) => {
            axios.post(routes.updateWebsiteContent, body).then((response) => {
                resolve(response)
            })
        })
    },
}