import React from "react";
import {
    AdvanceTablesWidget2,
    AdvanceTablesWidget4
} from "../widgets";
export function Revenue() {
    return (<>
            <div className="row">
                <h4>Revenue Page</h4>
                {/* <div className="col-xxl-12 order-2 order-xxl-1">
                    <AdvanceTablesWidget2 className="card-stretch gutter-b"/>
                </div> */}
                {/* <div className="col-lg-12">
                    <AdvanceTablesWidget4 className="card-stretch gutter-b" />
                </div> */}
            </div>
    </>);
}
